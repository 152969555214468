import React, { useState,useEffect } from 'react';
import {Button, Modal} from 'react-bootstrap'
import metamask from '../../Assets/images/metamask.png';
import walletconnect from '../../Assets/images/walletconnect.png'
import trustwallet from '../../Assets/images/trustwallet.png'
import arrow from '../../Assets/images/arrow.png'
import { useWeb3 } from "../../Hooks/UseWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../../Hooks/useWallet';
import { getAccount } from "../../Hooks/useAccounts";
import { sleep } from "../../Hooks/useContract";
import {style,iconTheme,position} from '../../Hooks/useToast'
import toast, { Toaster } from 'react-hot-toast';

const Connect = (props) =>{
    const[connect, setConnect] = useState(true)
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")
 
    useEffect(() => {
     setAccountInfo(getAccount);
     
 }, [accountInfo]); 
 
 const MetaMask = async () => {
     await MetamaskWallet()
     await sleep(1000)
     await useWeb3();
     setAccountInfo(localStorage.getItem("accountInfo"))
     if (localStorage.accountInfo) {
        sleep(500)
        setConnect(false)
        
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
         setTimeout(() => {
              window.location.reload()
         },1000);
        
     }
 }
 
 const Binance = async () => {
     await BinanceWallet()
     await sleep(1000)
     await useWeb3();
     setAccountInfo(localStorage.getItem("accountInfo"))
     if (localStorage.accountInfo) {
        setConnect(false)
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
         setTimeout(() => {
             
             window.location.reload()
         }, 1000);
 
        
     }
 
 }
 const enableWallet = async () => {
     const provider = await WalletConnect()
     set_walletConnect(provider)
 
     setAccountInfo(localStorage.getItem("accountInfo"))
    
     if (localStorage.getItem("accountInfo")) {
         setConnect(false)
         toast.success("Wallet Connected successfully", {
             position:position.position,
             style:style,
             iconTheme: iconTheme,
         })
        
         setTimeout(() => {
             
             window.location.reload()
         }, 1000);
     }
 
 }
    return(
        <>
         <Modal show={connect} centered size="md" className='connectmodal'>
            {/* <Modal.Header>
            <h5 className='cowa'>Connect Wallet</h5>
            <button className='btn btn-link themeclr close py-1 pe-1' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            </Modal.Header> */}
            <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span  className='fa fa-times'></span></button>
            <Modal.Body className='p-4 pt-0'>
                <div className='text-center mb-4'>  
                    <h5 className='cowa mb-2'>Connect Wallet</h5>
                    <p>
                        Please connect your wallet to continue. The system supports the following wallets.
                    </p>
                </div>
                <div className='mb-3'>
                     <div className='bordbox1 connect text-center' onClick={()=>MetaMask()}>
                        <div className='d-flex align-items-center gap-3'>
                            <img src={metamask} width="40" />
                            <p className='mb-0 mt-2'>Metamask</p>
                            <div className='flex-grow-1 text-end'>
                                <img src={arrow} width="10" />
                            </div>
                        </div>                         
                     </div>
                     <div className='bordbox1 connect text-center' onClick={enableWallet}>
                        <div className='d-flex align-items-center gap-3'>
                            <img src={walletconnect} width="40" />
                            <p className='mb-0 mt-2'>Wallet Connect</p>
                            <div className='flex-grow-1 text-end'>
                                <img src={arrow} width="10" />
                            </div>
                        </div>                         
                     </div>
                     <div className='bordbox1 connect text-center'  onClick={MetaMask}>
                        <div className='d-flex align-items-center gap-3'>
                            <img src={trustwallet} width="40" />
                            <p className='mb-0 mt-2'>Trust Wallet</p>
                            <div className='flex-grow-1 text-end'>
                                <img src={arrow} width="10" />
                            </div>
                        </div>                         
                     </div>
                </div>
            </Modal.Body>
         
        </Modal>
        </>
    )
}

export default Connect