import { React, useEffect } from "react";
import Header from "./Header1";
import Footer from "./Footer";
import { Container } from "react-bootstrap";
import { Aboutlists } from "../Actions/cmsFrontAxios";
import { useState } from "react";

const Explained = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    GetAboutlists();
  }, []);

  const [Aboutdata, setAboutdata] = useState();

  const GetAboutlists = async () => {
    try {
      let resp = await Aboutlists();
      console.log("GetParadigmlist", resp.data);
      setAboutdata(resp.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header />

      <article className="innerpages">
        <section className="explaincontents">
          <Container>
            <h1 className="innerhead">About Node AI</h1>
            <div className="paracontents">
              {Aboutdata &&
                Aboutdata.map((item) => {
                  return (
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}></p>
                  );
                })}






                 <p className="content">
              There is no greater story than people's relentless and dogged endeavor to overcome repressive regimes. Whether we notice it or not, centralized firms, markets and authorities are engaged in a never-ending disempowerment of human people's autonomy. Bittensor is creating a new future for humanity, where new economies and new commodities are decentralized by design and where no single entity is a sole authority.
              </p>
              <p className="content">
              At the core of the Bittensor ecosystem is the production, marketing and selling of digital commodities. At the expanding periphery of this ecosystem are the entire internet geographies of ecosystems.
              </p>
              <p className="content">
              Everything is decentralized. Digital commodities like compute, data, storage, predictions, and models are transformed into intelligence. When digital commodities are recast as intelligence, then new architectures are discovered, new commodities are produced and surprisingly cheaper ways to achieve innovations are being revealed—the possibilities are turning out to be limitless.
              </p>
              <p className="content">
              NAI, the decentralized currency, fuels the production of this intelligence in subnets.These intelligence-producing subnets are then innovatively connected in productive and profitable ways, feeding one intelligence into another.
              </p>
              <p className="content">
              Entrepreneurs with skills and ideas will use Bittensor when they are deprived of investments from traditional sources of capital. And most important, any such entrepreneur can participate profitably and thrive in the Bittensor ecosystem.
              </p>
                   </div>
          </Container>
        </section>
      </article>

      <Footer />
    </>
  );
};

export default Explained;
