
import './App.css';
import './Style.css';
import './home.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Home from './Component/Home';
import Launchpad from './Component/Launchpad';
import LockedStake from './Component/LockedStaking';
import News from './Component/News';
import Newsdetails from './Component/Newsdetails';
import Articles from './Component/Articles';
import toast, { Toaster } from 'react-hot-toast';
import Map from './Component/Map';

import Demo from "./Component/demo";
import Home from './Component/Home';
import Explained from './Component/Explained';
import About from './Component/About';
import Philosophy from './Component/Philosophy';
import Charter from './Component/Charter';
import Whitepaper from './Component/Whitepaper';

function App() {
  return (
    <div className="App">
       <BrowserRouter >
       <div><Toaster /></div>
       <Routes>
          {/* <Route path="/" element={<Land />} /> */}
          {/* <Route path="/" element={<Home1 />} /> */}
          {/* <Route path="/" element={<Demo />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/staking" element={<LockedStake />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsdetails" element={<Newsdetails />} />
          <Route path="/newsdetails/:id" element={<Newsdetails />} />
          <Route path="/latestarticles/:id" element={<Articles />} />
          <Route path="/map" element={<Map />} />
          {/* <Route path="/explained" element={<Explained />} />
          <Route path="/about" element={<About />} /> */}
          <Route path="/about" element={<Explained />} />
          <Route path="/paradigm" element={<About />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/charter" element={<Charter />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          
          <Route path="/demo" element={<Demo />} />

       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
