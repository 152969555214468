export const ONEDAYINSECONDS = 86400;
export const frontendurl = window.location.origin;

// export const backurl = "http://localhost:2053"; // local

// export const IMG_URL = "http://localhost:2053"; // local

// export const backurl = "https://backend-nodeai.maticz.in"; // testnet/
export const IMG_URL = "https://api.nai.systems"; 
 export const backurl = "https://api.nai.systems"// mainnet

export const BURN = "0x000000000000000000000000000000000000dEaD";

export const BLOCKS_PER_YEAR = 1051200;

export const CHAINS = [
  // {
  //   NAME: "BSC-TEST",
  //   RPC_URL: "https://bsc-testnet.publicnode.com/", //"https://endpoints.omniatech.io/v1/bsc/testnet/public", // BSC TESTNET
  //   CHAIN_ID: 97,
  //   TOKENADDRESS: "0x7933DEC3A4Ccf7304348B2e59F324EFDd7Aa7078", //
  //   USDTADDRESS: "0x54C09A5856B08D795079cB1Ca61f55afD85Be39a",
  //   MASTERCHEF: "0xcA491033CDCdE5407F5c7fCFC84209312d0AdBa4", //  BSC NEW TESTNET
  //   LOCKEDSTAKING: "0x74F7f9234835ddE5fAa395044B2E0476a5aa12f7",
  // },

  //   {
  //     NAME: "AVALANCHE-TEST",
  //     RPC_URL:" https://api.avax-test.network/ext/bc/C/rpc", // BSC TESTNET
  //     CHAIN_ID:43113,

  //     TOKENADDRESS: "0x2Cc943EaD300975190355cd9A2e93918555A91C3", //
  //     USDTADDRESS : "0x8d9f46183A23d81dcCc172C92bDb4553c1b67FFf",
  //     MASTERCHEF: "0xD62c4876fE4A45B8886e9bd78eB905cfee7ef2A3",
  //     LOCKEDSTAKING : "0xe50bD7e7DE209cC9e68b18CA1448307B998931A7",

  // },
  //   {
  //     NAME: "BSC-TEST",
  //     RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc", // BSC TESTNET
  //     CHAIN_ID:43113,
  //     ROUTER: "0x1F0929D79d2e992C31dD70A637C33e4a8440d3b0",
  //     TOKENADDRESS: "0x3F1c44C8b7e672674Ee9faE3B96cB3B14F034d72", //
  //     MASTERCHEF: "0x5d012e3a0Aa2abfF0862a72FB5F876028FE751B3", //  BSC NEW TESTNET

  // },
  //   {
  //     NAME: "Polygon-TEST",
  //     RPC_URL:"https://matic-mumbai.chainstacklabs.com",
  //     CHAIN_ID:80001,
  //     TOKENADDRESS: "0x9433f3BaAE2984C6F5C8DE6989aB6dee23eaEE17",
  //      MASTERCHEF: "0x74962D8c90dCb1e4761DF94115BE449044179e5A", // new
  //     // MASTERCHEF: "0x1afd408a544e901af55cB8735d8c6B49c47A11b2",//old
  //     NATIVE: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B"
  // },
     {
       NAME: "BSC-MAINNET",
       RPC_URL:"https://bsc-dataseed1.binance.org/",
      // RPC_URL :  https:bsc-dataseed.binance.org,
       CHAIN_ID:56,
       TOKENADDRESS: "0x9A23c30B1A7e0831cfea2A667D7862CF90F129e1", //NodeAI
       USDTADDRESS :"0x55d398326f99059fF775485246999027B3197955", // USDTADDRESS
       NATIVE: "0x68e0e146FBC73561716BcDD27F429aEAF80ED52f",
       MASTERCHEF: "0xf861E38689A0454F0C1F1d29d6f0df7A994444bD", // launchpad proxy
       LOCKEDSTAKING : "0xad45F6e546604DDcbb18f7294a160D8E8C281503" // locked staking
   }
];
