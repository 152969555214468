import axios from "axios"

import {backurl} from "../Config/env"


export const getCmslists = async (data) => {
    const resp = await axios({
        method: 'GET',
        url: `${backurl}/cms/cmslist`,
        "headers": {
            "Content-Type": 'application/json'
        },
        data: data,
    })
    console.log(resp.data);
    return resp.data;
}


export const getsociallists = async (data) => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/addsociallink`,
        "headers": {
            "Content-Type": 'application/json'
        },
        data: data,
    })
    console.log(resp.data);
    return resp.data;
}



export const getcmssingledata = async (data) => {
    const resp = await axios({
        method: 'GET',
        url: `${backurl}/cms/getFeaturedata`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}


export const Roadmaplists = async () => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/roadmaplistfront`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}

export const Aboutlists = async () => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/Front-Aboutlist`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}



export const Paradigmlist = async () => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/Front-Paradigmlist`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}


export const Philosophylist = async () => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/Front-Philosophylist`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}


export const Charterlist = async () => {
    const resp = await axios({
        method: 'POST',
        url: `${backurl}/cms/Front-Charterlist`,
        "headers": {
            "Content-Type": 'application/json'
        },

    })
    console.log(resp.data);
    return resp.data;
}



