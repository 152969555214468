import React, { useEffect, useState } from "react";
import logoicon from "../Assets/images/cardlogo.png";
import { ProgressBar } from "react-bootstrap";
import { UsegetSaleInfo } from "../Hooks/useContract";
import { getAccount } from "../Hooks/useAccounts";
import Masp from "./Modal/MaspModal";

import sustainable from "../Assets/images/slide/sustainable.png";
import power from "../Assets//images/slide/power-gauge.gif";

import tabletop from "../Assets//images/slide/tabletop.png";
import tablebottom from "../Assets//images/slide/tablebottom.png";
import Lottie from "lottie-react";
import Images from "./Simage";

const LaunchpadCont = (props) => {
  const [masp, setMasp] = useState(false);
  const [LaunchpadData, setLaunchpadData] = useState({});
  const [accountInfo, setAccountInfo] = useState(getAccount());
  const [yieldValue, setyield] = useState("");
  const [presaleaddress, setpresaleaddress] = useState(
    props?.data?.presaleAddress
  );
  const [progressBNB, setprogressBNB] = useState(0);
  const [progressUSDT, setprogressUSDT] = useState(0);
  const [tokensymbol, settokensymbol] = useState("");
  const [PoolDays, setPoolDays] = useState("");



  useEffect(() => {
    console.log("data>>>", props?.data);
    getData(props?.data?.presaleAddress);
  }, [presaleaddress]);

  const getData = async (value) => {
    try {
      var data =
        presaleaddress && value != ""
          ? await UsegetSaleInfo(value, accountInfo)
          : "";
      setLaunchpadData(data);
      var progressbnb = (
        (parseInt(data?.bnbearnedcap) / parseInt(data?.hardCap)) *
        100
      ).toFixed(2);
      var progressUSDT = (
        (parseInt(data?.busdearnedcap) / parseFloat(data?.busdhard)) *
        100
      ).toFixed(2);
      setprogressBNB(progressbnb);
      setprogressUSDT(progressUSDT);
      settokensymbol(data?.presaletokensymbol);
    } catch (e) {
      console.log("getData", e);
    }

    const a = "0.05%";
    const b = "2%";
    const c = "15%";
    const d = "0.05%";
    const e = "2%";
    const f = "15%";
    const g = "1.5%";

    if (props?.pid === 0) {
      setyield(a);
      setPoolDays("90 Days");
    }
    if (props?.pid === 1) {
      setyield(b);
      setPoolDays("180 Days");
    }
    if (props?.pid === 2) {
      setyield(c);
      setPoolDays("365 Days");
    }
    if (props?.pid === 3) {
      setPoolDays("5 Days");
      setyield(g);
    }

    if (props?.pid === 4) {
      setPoolDays("90 Days");
      setyield(d);
    }
    if (props?.pid === 5) {
      setPoolDays("180 Days");
      setyield(e);
    }
    if (props?.pid === 6) {
      setPoolDays("365 Days");
      setyield(f);
    }
    if (props?.pid === 7) {
      setPoolDays("90 Days");
      setyield(a);
    }
  };

  return (
    <div className="launchbox br-14 mt-5 position-relative">
      <div class="lines">
        <div class="line1"></div>
        <div class="line1"></div>
        <div class="line1"></div>
      </div>
      {/* <img className='img-fluid defrobo1' src={downrobo} alt='alirobo' /> */}
      {/* <img className='img-fluid ployright ploycont' src={power} alt='alirobo' /> */}
      {masp && (
        <Masp
          presaleaddress={presaleaddress}
          tokensymbol={props?.data?.tokenSymbol}
          onDismiss={() => setMasp(false)}
        />
      )}
      <div className="lauchboxhead">
        <div>
          <div className="logocont">
            <img src={logoicon} />
            <span className="me-1">{props?.data?.tokenSymbol} </span>
            <span className="textclr ml-4">{PoolDays}</span>
          </div>
          <h5 className="textclr mb-0">
            {props?.pid == 0 || props?.pid == 1 || props?.pid == 2
              ? "Option 1"
              : props?.pid == 3
              ? "Tutorial"
              : props?.pid == 4 || props?.pid == 5 || props?.pid == 6
              ? "Option 2"
              : props?.pid == 7
              ? "Flash Pool"
              : ""}

            {console.log(" props?.pid == 10", props.pid)}
          </h5>
        </div>
        <div>
          {LaunchpadData && LaunchpadData?.ispresaleopen ? (
            <button className="chocobtn">On Process</button>
          ) : (
            <button className="chocobtn">
              {LaunchpadData && LaunchpadData?.isclaimable
                ? "Completed"
                : "Stopped"}
            </button>
          )}
        </div>
      </div>

      <div className="bordbox">
        <div className="bordboxinner">
          <div>
            <p className="textclr mb-2">Sale Price</p>
            {/* <h6>1 BNB = {LaunchpadData && LaunchpadData?.pricePer || 0} {tokensymbol}</h6> */}
            <h6>
              1 USDT = {(LaunchpadData && LaunchpadData?.pricePerCrypto) || 0}{" "}
              {tokensymbol}
            </h6>
          </div>
          <div>
            <p className="textclr mb-2">Yield</p>
            <p>{yieldValue}</p>
          </div>

          <div>
            <p className="textclr mb-2">Presale HardCap</p>
            {/* <h6>{LaunchpadData && LaunchpadData?.hardCap || 0} BNB & {LaunchpadData && LaunchpadData?.busdhard ||0} USDT</h6> */}

            <h6> {(LaunchpadData && LaunchpadData?.busdhard) || 0} USDT</h6>
          </div>
          <div>
            <p className="textclr mb-2">Min Allocation</p>
            {/* <h6>{LaunchpadData && LaunchpadData?.minamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdminamount || 0} USDT</h6> */}

            <h6>
              {" "}
              {(LaunchpadData && LaunchpadData?.busdminamount) || 0} USDT
            </h6>
          </div>
          <div>
            <p className="textclr mb-2">Max Allocation</p>
            {/* <h6>{LaunchpadData && LaunchpadData?.maxamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdmaxamount ||0 } USDT</h6> */}

            <h6>
              {" "}
              {(LaunchpadData && LaunchpadData?.busdmaxamount) || 0} USDT
            </h6>
          </div>
          <div>
            <p className="textclr mb-2">Claimable {tokensymbol}</p>
            <p>
              {parseFloat(LaunchpadData && LaunchpadData?.claimb).toFixed(5) ||
                0}{" "}
              {tokensymbol}
            </p>
          </div>
          <div>
            <p className="textclr mb-2">Access</p>
            <h6>Public</h6>
          </div>
        </div>
        <div className="row mt-5">
          {/* <div className='col-md-6 mb-4'>
                <h6>Progress ({LaunchpadData && LaunchpadData.bnbearnedcap || 0} / {LaunchpadData && LaunchpadData?.hardCap || 0} BNB ) {progressBNB|| 0} %</h6>
                <ProgressBar now={((parseInt(LaunchpadData && LaunchpadData?.bnbearnedcap)/parseInt(LaunchpadData && LaunchpadData?.hardCap))*100)} />
            </div> */}
          <div className=" mb-4">
            <h6 className="progresshead">
              Progress ({(LaunchpadData && LaunchpadData.busdearnedcap) || 0} /{" "}
              {(LaunchpadData && LaunchpadData?.busdhard) || 0} USDT ){" "}
              {progressUSDT || 0} %
            </h6>
            <ProgressBar
              now={
                (parseInt(LaunchpadData && LaunchpadData?.busdearnedcap) /
                  parseInt(LaunchpadData && LaunchpadData?.busdhard)) *
                100
              }
            />
          </div>
        </div>
        {
          console.log(LaunchpadData?.ispresaleopen,"LaunchpadData?.ispresaleopen")
        }
        {LaunchpadData?.ispresaleopen ? (
          <p className="textclr mt-3">
            End Date: {LaunchpadData && LaunchpadData?.saleEndTime} UTC
          </p>
        ) : (
          <>
            <p className="textclr mt-3">Sale Not Live</p>
          </>
        )}

        <div className="text-end">
          {accountInfo == "" || accountInfo == null ? (
            <button className="btn userbtn headingbtn">Unlock Wallet</button>
          ) : (
            <button
              className="btn userbtn headingbtn"
              onClick={() => setMasp(true)}>
              Buy
            </button>
          )}
        </div>
      </div>

      <Lottie
        animationData={Images.infostar}
        loop={true}
        className="cardstar"></Lottie>
      <img src={Images.launchlines} alt="" className="launchlines" />
      <img src={Images.launchlines} alt="" className="launchlinestwo" />
    </div>
  );
};

export default LaunchpadCont;
