import { React, useEffect } from "react";
import Header from "./Header1";
import Footer from "./Footer";
import { Container } from "react-bootstrap";
import { Paradigmlist } from "../Actions/cmsFrontAxios";
import { useState } from "react";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    GetParadigmlist();
  }, []);

  const [Paradigmdata, setParadigmdata] = useState();

  const GetParadigmlist = async () => {
    try {
      let resp = await Paradigmlist();
      console.log("GetParadigmlist", resp.data);
      setParadigmdata(resp.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header />

      <article className="innerpages">
        <section className="explaincontents">
          <Container>
            <h1 className="innerhead">Node AI Paradigm</h1>
            <div className="paracontents">
              {/* {Paradigmdata &&
                Paradigmdata?.map((item) => {
                  return (
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}></p>
                  );
                })} */}


<p className="content">
NordAI's vision for a decentralized AI company has captivated a large number of people in the arenas of Artificial Intelligence and computer science, who have yearned for an alternative to the top-down world being created by our current technology giants. However the promise of what NordAI is and can be has been masked by large technical barriers to understanding, and thus has not been well digested by non-technical audiences.              </p>
              <p className="content">
              The purpose of this article is to answer why NordAI matters to the world, why you should care and why we need to build it together. These are core questions, and each of them will require that we delve into the history of cryptocurrency, and how NordAI is uniquely positioned to play a significant role in the future that is being written today.              </p>
              <p className="content">
              The invention of Bitcoin was a revolutionary moment for humanity because it introduced two simultaneously united yet distinct technological innovations, reshaping the way that humans organize themselves. Primarily, it was the first ever decentralized currency, which gave humanity a shared value system - one that could not be mutated by vested or centralized interests. With this, Bitcoin promised a future of fair, anti-fiat, finance.              </p>
              <p className="content">
              Secondly and to achieve this aim, it birthed the original, disseminated, and permissionless digital commodity market. This second point has not been overlooked by anyone who has been watching closely - the Bitcoin network's computational power, measured in raw hashing power, has blast past the potential of any company or government.              </p>
              <p className="content">
              NordAI is essentially a language for writing numerous decentralized commodity markets, or 'subnets', situated under a unified token system. These distinct markets function through NordAI's blockchain, allowing each to interact and join into a singular computing infrastructure. By analogy, NordAI brings the same type of abstraction which Ethereum added to Bitcoin for running decentralized contracts, but onto Bitcoin's inverse innovation — digital markets.              </p>

              <p className="content">
              Compared to Bitcoin and other cryptocurrencies attempting to leverage the digital marketplace, NordAI has built a framework that provides ease for creating these viable and enormously powerful systems. However, its genius lies in the fact that every one of these inter-networked markets is connectable, and available, to the whole. Building a hierarchical web of resources, ultimately culminating in the production of intelligence; intelligence leverages computation, which leverages data, which leverages storage, then finally leveraging oracles and data procurement and into infinity, all within the same ecosystem.  </p>    
                  <p className="content">This is NordAI's overarching vision; directing the power of digital markets towards society's most important digital commodity - Artificial Intelligence. Not only to build the most powerful intelligence network, but also to ensure that the benefits and the ownership of machine intelligence are in the hands of mere mortals. Bottom up, rather than top down.</p>
                  
                  <p className="content">NordAI's goal and function of this technological foundation has several implications. For developers, a language to write markets for bespoke commodities such as compute, allowing them to take advantage of the enormous size, cost-efficiency and natural functionality of decentralized market systems. For front-end customers, NordAI offers access to resources at a cheaper price and without intermediary;unclosable, not falsely self-proclaimed as “open”. For the NordAI network as a whole, to create Machine Intelligence, in an open and equitable manner on top of ordered and dependent sub-markets. And finally - for the world, to ensure that the supremely important commodity of intelligence is owned by everyone.</p>
                 <p className="content"> NordAI is, in a similar way, also a computer - albeit one with differing problems such as market misalignments - but one that is programmable, with the potential to become the most powerful ever created. And, it is a shared computer - not directly owned by any single entity, and available for anyone's use. To run, govern, contribute to, and importantly profit from the technological products it produces.</p> 

            
            </div>
          </Container>
        </section>
      </article>

      <Footer />
    </>
  );
};

export default About;
