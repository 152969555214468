import React, { useEffect, useState } from "react";
import calc from "../Assets/images/slide/calc.png";
import logoicon from "../Assets/images/cardlogo.png";
import { ONEDAYINSECONDS } from "../Config/env";
import { getAccount } from "../Hooks/useAccounts";
import {
  approveContract,
  checkIsApproved,
  Claim,
  getUser,
  getUserBalance,
  getUserReward,
  UseTokenInfo,
} from "../Hooks/useStake";
import UnlockModal from "./Modal/UnlockWalletMadal";
import WithdrawModal from "./Modal/WithdrawModal";
import sustainable from "../Assets/images/slide/sustainable.png";
import power from "../Assets//images/slide/power-gauge.gif";

const LockedStackCont = (props) => {
  const [unlock, setUnlock] = useState(false);
  const [Withdraw, setWithdraw] = useState(false);
  const [pooldays, setpoolDays] = useState("");
  const [status, setStatus] = useState(false);
  const [accountInfo, setAccountInfo] = useState(getAccount());
  const [userInfo, setUserInfo] = useState({});
  const [userstatedate, setuserstatedate] = useState("-");
  const [tokenInfo, setTokenInfo] = useState(null);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  const [pending, setPending] = useState(0);
  const [yieldValue, setyield] = useState("0");
  const [isApproved, setIsApproved] = useState(false);
  const [userTokenBalance, setUserTokenBalance] = useState("");

  useEffect(() => {
    console.log("props?.pid", props?.pid);
    if (accountInfo) {
      getData();

      if (props.datas.lpToken && props.datas.lpToken) {
        approveFunction(props.datas.lpToken);
      }
      getUserTokenBalance();
    }
  }, [accountInfo]);

  const getData = async () => {
    var poolday = parseInt(props.datas.lockedPeriod) / ONEDAYINSECONDS;
    setpoolDays(String(poolday).substring(0, 3));
    if (poolday <= 0) {
      setStatus(true);
    }

    var getuser = await getUser(props.pid, accountInfo);
    const datee = new Date(parseInt(getuser.userLockedPeriod) * 1000);
    const unstakeDate = datee.toLocaleDateString();
    if (getuser.userLockedPeriod > 0) setuserstatedate(unstakeDate);

    var TokenInfo = await UseTokenInfo(props.datas.lpToken);
    setTokenInfo(TokenInfo);
    setUserStakedAmount(getuser.amount);

    var UserReward = await getUserReward(props.pid, accountInfo);
    const dataF =
      (parseInt(UserReward) + parseInt(getuser.pendingRewards)) /
      10 ** parseInt(TokenInfo?.decimals);
    setPending(dataF.toFixed(5));

    const a = "0.05%";
    const b = "2%";
    const c = "5%";
    const d = "0.05%";
    const e = "2%";
    const f = "5%";

    if (props?.pid === 0) {
      setyield(a);
    }
    if (props?.pid === 1) {
      setyield(b);
    }
    if (props?.pid === 2) {
      setyield(c);
    }
    if (props?.pid === 3) {
      setyield(d);
    }
    if (props?.pid === 4) {
      setyield(e);
    }
    if (props?.pid === 5) {
      setyield(f);
    }
  };

  const claim = async () => {
    await Claim(getAccount(), props.pid);
  };

  const approveFunction = async (data) => {
    if (data) {
      const approval = await checkIsApproved(
        accountInfo,
        data ? data : tokenInfo.addres
      );
      setIsApproved(approval);
    }
  };

  const approve = async () => {
    if (accountInfo) {
      await approveContract(accountInfo, props.datas.lpToken);
      setIsApproved(true);
    }
  };

  const getUserTokenBalance = async () => {
    if (props.datas.lpToken) {
      var userbalance = await getUserBalance(props.datas.lpToken, accountInfo);

      setUserTokenBalance(userbalance);
    }
  };
  return (
    <div>
      {unlock && (
        <UnlockModal
          data={tokenInfo}
          userTokenBalance={userTokenBalance}
          pid={props.pid}
          onDismiss={() => setUnlock(false)}
        />
      )}
      {Withdraw && (
        <WithdrawModal
          data={tokenInfo}
          userStakedAmount={userStakedAmount}
          unstakeDate={userstatedate}
          pid={props.pid}
          onDismiss={() => setWithdraw(false)}
        />
      )}
      <div className="launchbox">
        {/* <img className='img-fluid ployright ploystackcont' src={power} alt='alirobo' /> */}
        <div className="d-flex align-items-center jc-between">
          <div className="logocont">
            <img src={logoicon} /> <span>{tokenInfo && tokenInfo?.symbol}</span>
          </div>
          <div>
            <button type="button" className="ovalbtn">
              {pooldays || 0} Days
            </button>
          </div>
        </div>
        <h5 className="textclr ml-4">
          {props?.pid == 0 || props?.pid == 1 || props?.pid == 2
            ? "Option 1"
            : props?.pid == 6
            ? "Tutorial"
            : props?.pid == 7 || props?.pid == 8 || props?.pid == 9
            ? "Option 2"
            : props?.pid == 10
            ? "Flash Pool"
            : ""}
        </h5>

        <div className="d-flex jc-between align-items-center mt-3 gap-3">
          <p className="mb-0 livelabel">
            Unstake available after :{" "}
            {userstatedate ? userstatedate : userstatedate}
          </p>
          <button type="button" className="chocobtn">
            {status ? "Ended" : "Live"}
          </button>
        </div>
        <div className="bordbox1">
          <div className="d-flex jc-between gap-2">
            <div>
              <div className="mb-3">
                <p className="mb-1">Staked</p>
                <p className="mb-0">
                  {(userStakedAmount &&
                    (
                      parseInt(userStakedAmount) /
                      10 ** parseInt(tokenInfo && tokenInfo?.decimals)
                    ).toFixed(2)) ||
                    0}
                </p>
              </div>
              <div>
                <p className="mb-1">Earned {tokenInfo && tokenInfo?.symbol}:</p>
                <p className="mb-0">{pending || 0}</p>
              </div>
            </div>

            <div>
              <p className="mb-1">APR</p>
              <p className="mb-0">
                <img src={calc} className="calcicon" />{" "}
                {(
                  (props.datas.MetaPerBlock / 10 ** 18) *
                  Number(pooldays)
                ).toFixed(2)}{" "}
                %
              </p>

              {/* <p><img src={calc} className="calcicon" /> {((props.datas.MetaPerBlock / 10 ** Number(tokenInfo && tokenInfo?.decimals)) * Number(pooldays)).toFixed(2)} %</p> */}
            </div>
          </div>
        </div>
        <div className="text-center">
          {accountInfo == "" || accountInfo == null ? (
            <button className="btn userbtn headingbtn d-none">
              Unlock Wallet
            </button>
          ) : isApproved ? (
            <div className="d-flex plusminus justify-content-between flex-1 gap-sm-4 gap-2">
              <button className="btn userbtn headingbtn" onClick={claim}>
                Claim
              </button>
              <div className="d-flex justify-content-end gap-2">
                {" "}
                <button
                  className="btn userbtn headingbtn small"
                  onClick={() => setUnlock(true)}>
                  +
                </button>
                <button
                  className="btn userbtn headingbtn small"
                  onClick={() => setWithdraw(true)}>
                  -
                </button>
              </div>
            </div>
          ) : (
            <button
              className="btn userbtn headingbtn"
              onClick={() => approve()}>
              Approve
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LockedStackCont;
