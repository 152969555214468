

const Images = {
    greenline: require("../Assets/images/greenline.json"),
    infoweb: require("../Assets/images/infoweb.json"),
    infomobile: require("../Assets/images/infomobile.json"),
    infostar: require("../Assets/images/star.json"),
    headline: require("../Assets/images/headline.json"),
    trust: require("../Assets/images/trust.json"),
    infobg: require("../Assets/images/infobg.json"),
    featurebg: require("../Assets/images/featurebg.json"),
    featurevertical: require("../Assets/images/featurevertical.json"),
    featimgone: require("../Assets/images/featimgone.svg").default,
    featimgtwo: require("../Assets/images/featimgtwo.svg").default,
    featimgthree: require("../Assets/images/featimgthree.svg").default,
    featimgfour: require("../Assets/images/featimgfour.svg").default,
    featlogo: require("../Assets/images/featlogo.json"),
    clients: require("../Assets/images/clients.json"),
    clientsbg: require("../Assets/images/clientsbg.json"),
    roadmapbg: require("../Assets/images/roadmapbg.json"),
    telegram: require("../Assets/images/telegram.svg").default,
    twitter: require("../Assets/images/twitter.svg").default,
    instagram: require("../Assets/images/instagram.svg").default,
    discord: require("../Assets/images/discord.svg").default,
    flogo: require("../Assets/images/flogo.png").default,
    bannercenter: require("../Assets/images/bannercenter.json"),
    bannercenterlines: require("../Assets/images/bannercenterlines.json"),
    innerimg: require("../Assets/images/innerimg.json"),
    launchlines: require("../Assets/images/launchlines.gif").default,



    // feathead: require("../Assets/images/feathead.png"),
    // featbody: require("../Assets/images/featbody.png"),

    partnersimg: [
        {
            partnerimg: require("../Assets/images/partnerone.png").default
        },
        {partnerimg: require("../Assets/images/partnertwo.png").default},
        {partnerimg: require("../Assets/images/partnerthree.png").default},
        {partnerimg: require("../Assets/images/partnerfour.png").default},
        {partnerimg: require("../Assets/images/partnerfour.png").default},
        {partnerimg: require("../Assets/images/partnerfour.png").default},
    ],

    whitepaperone: require("../Assets/images/whitepaper/whitepaperone.png").default,
    whitepapertwo: require("../Assets/images/whitepaper/whitepapertwo.png").default,
    whitepaperthree: require("../Assets/images/whitepaper/whitepaperthree.png").default,
    whitepaperfour: require("../Assets/images/whitepaper/whitepaperfour.png").default,
    whitepaperfive: require("../Assets/images/whitepaper/whitepaperfive.png").default,
    whitepapersix: require("../Assets/images/whitepaper/whitepapersix.png").default,
    whitepaperseven: require("../Assets/images/whitepaper/whitepaperseven.png").default,
    whitepapereight: require("../Assets/images/whitepaper/whitepapereight.png").default,
    whitepapernine: require("../Assets/images/whitepaper/whitepapernine.png").default,
    whitepaperten: require("../Assets/images/whitepaper/whitepaperten.png").default,
    whitepapereleven: require("../Assets/images/whitepaper/whitepapereleven.png").default,
    whitepapertwelve: require("../Assets/images/whitepaper/whitepapertwelve.png").default,


    imageone: require("../Assets/images/philosophy/imageone.png").default,
    imagetwo: require("../Assets/images/philosophy/imagetwo.gif").default,
    imagethree: require("../Assets/images/philosophy/imagethree.png").default,
    imagefour: require("../Assets/images/philosophy/imagefour.gif").default,
    imagefive: require("../Assets/images/philosophy/imagefive.png").default,
    imagesix: require("../Assets/images/philosophy/imagesix.png").default,
    imageseven: require("../Assets/images/philosophy/imageseven.gif").default,
    imageeight: require("../Assets/images/philosophy/imageeight.gif").default,
    imagenine: require("../Assets/images/philosophy/imagenine.gif").default,
    imageten: require("../Assets/images/philosophy/imageten.gif").default,
    imageeleven: require("../Assets/images/philosophy/imageeleven.gif").default,
    imagetwelve: require("../Assets/images/philosophy/imagetwelve.gif").default,
    imagethirteen: require("../Assets/images/philosophy/imagethirteen.gif").default,
    imagefourteen: require("../Assets/images/philosophy/imagefourteen.gif").default,
}

export default Images;
