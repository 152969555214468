import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer';
import Header from './Header1';
import { Tabs, Tab } from 'react-bootstrap';
import Masp from './Modal/MaspModal';
import LaunchpadCont from './LaunchpadCont';
import logoicon from '../Assets/images/slide/openGPT.png'
import { getLaunchpadData } from '../Hooks/UseBackend';
import Typed from 'react-typed';

// import circle from "../Assets/images/circle.png";

import loopsimg from "../Assets/images/slide/loop-gif.gif";
import loopsleftimg from "../Assets/images/slide/loopleft.gif";
import targets from "../Assets/images/slide/target.gif";
import Lottie from 'lottie-react';
import Images from './Simage';

const Launchpad = () => {
    const [masp, setMasp] = useState(false)
    const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])


    useEffect(() => {

        getData()
    }, [])

    const getData = async () => {

        var data = await getLaunchpadData();
        console.log("data>>>>",data);
        settotalLaunchPadlength(data)



        //  data.map(async(item,index) =>{
        //  var result = await UsegetSaleInfo(item?.presaleAddress);

        //  })
    }

    useEffect(() => {
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth"
        });
    }, []);
   


    return (
        <div className='launchpad'>
            <Header />
            <main className='allsection'>
                <section className='banner'>
                    <div className='container container-theme'>
                        <div className='bannercenter'>
                            <h1 className='innerheading'>Launchpad</h1>
                            <div className='titles'>
                                <Typed
                                    strings={['Buy New Tokens Before They Are Launched for Trading']}
                                    typeSpeed={100}
                                    cursorChar={"_"}
                                    loop
                                />
                            </div>
                            <div class="lines">
                                <div class="line1"></div>
                                <div class="line1"></div>
                                <div class="line1"></div>
                                {/* <div class="line1"></div> */}
                            </div>
                        </div>
                        {/* <Lottie animationData={Images.innerimg} loop={true}></Lottie> */}
                    </div>
                </section>
                <section className='tabsection'>
                    <div className='container container-theme'>                  
                        
                     
                        <div className='menu_list position-relative'>

                            <div className='notice_link'> <a target="_blank"
                                // href='https://www.labelm.io/newsdetails/63d1edb1ecf0432c7ebd9869' 
                                href='/'
                                className='noticelink'>-Notice-</a></div>
                            <div>
                                <Tabs defaultActiveKey="all" id="uncontrolled-tab-example"  >
                                    <Tab eventKey="all" title="All">
                                        {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => <LaunchpadCont data={item} pid={index} /> )}
                                    </Tab>
                                    <Tab eventKey="launch1" title="90 Days">
                                        {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 0 || item.pid == 3 || item.pid == 7 || item.pid == 10 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                    </Tab>
                                    <Tab eventKey="launch2" title="180 Days" >
                                        {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 1 || item.pid == 4 || item.pid == 8  ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                    </Tab>
                                    <Tab eventKey="launch3" title="365 Days" >
                                        {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 2 || item.pid == 5 ||  item.pid == 9 ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                    </Tab>
                                    {/* <Tab eventKey="launch4" title="5 Days" >
                                        {totalLaunchPadlength && totalLaunchPadlength.map((item, index) => (item.pid == 6  ? <LaunchpadCont data={item} pid={index} /> : <></>))}
                                    </Tab> */}

                                </Tabs>

                            </div>

                        </div>

                    </div>
                </section>
                
            </main>
            <Footer />
        </div>
    )
}

export default Launchpad